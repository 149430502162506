import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { ReactComponent as AppStoreSvg } from './svg/app-store-mobile.svg';
import { ReactComponent as GooglePlaySvg } from './svg/google-play-mobile.svg';

const GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.getclean.by';
const APPLE_URL = 'https://apps.apple.com/by/app/getclean/id6739940609';

const FooterMobileStores = () => {
  return (
    <Component>
      <StoreLink href={GOOGLE_URL} target="_blank">
        <GooglePlaySvg />
      </StoreLink>
      <StoreLink href={APPLE_URL} target="_blank">
        <AppStoreSvg />
      </StoreLink>
    </Component>
  );
};

export default FooterMobileStores;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  ${media.mobile(css`
    align-items: center;
  `)}
`;

const StoreLink = styled.a`
  display: block;
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.75;
  }
`;
