import React from 'react';
import styled from 'styled-components';

import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import IconCheckbox from '@/components/IconCheckbox';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOrderId,
  selectOrderUsedBalance,
  selectUseBalance,
  selectUsedBalance,
  selectUserBalance,
  setUseBalance,
  setUsedBalance,
} from '@/modules/Order/store/common';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import TextInput from '@/components/TextInput';
import Button from '@/components/Button';

const OrderFormBalance = () => {
  const dispatch = useTypedDispatch();
  const useBalance = useTypedSelector(selectUseBalance);
  const userBalance = useTypedSelector(selectUserBalance);
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);
  const usedBalance = useTypedSelector(selectUsedBalance);
  const orderUsedBalance = useTypedSelector(selectOrderUsedBalance);

  const userBalanceWithOrder = userBalance + orderUsedBalance;

  if (!userBalanceWithOrder) {
    return null;
  }

  const onToggle = () => {
    if (useBalance) {
      dispatch(setUseBalance(false));
      dispatch(setUsedBalance(0));
    } else {
      dispatch(setUseBalance(true));
      dispatch(
        setUsedBalance(
          orderUsedBalance
            ? orderUsedBalance
            : Math.min(0, userBalanceWithOrder)
        )
      );
    }
  };

  return (
    <OrderFormSection
      blocked={submitLoading}
      title="Баланс"
      badge={`${userBalanceWithOrder.toFixed(2)} баллов`}
    >
      <IconCheckbox
        label="Использовать бонусные баллы для оплаты заказа"
        checked={useBalance}
        onChange={onToggle}
      />

      {useBalance ? (
        <FormContainer>
          <TextInputWrapper>
            <TextInput
              label="Использовать баллов:"
              value={usedBalance}
              onChange={(e) => {
                dispatch(setUsedBalance(+e.target.value));
              }}
              numeric={true}
              numericDecimal={true}
            />
          </TextInputWrapper>

          {userBalance !== usedBalance ? (
            <ButtonWrapper>
              <Button
                modeVariant="text"
                colorVariant="green"
                onClick={() => {
                  dispatch(setUsedBalance(userBalanceWithOrder));
                }}
              >
                Списать все баллы
              </Button>
            </ButtonWrapper>
          ) : null}
        </FormContainer>
      ) : null}
    </OrderFormSection>
  );
};

export default OrderFormBalance;

const FormContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const TextInputWrapper = styled.div`
  max-width: 170px;
  input {
    text-align: center;
    font-size: 20px;
  }
`;

const ButtonWrapper = styled.div`
  padding-bottom: 8px;
`;
