import React from 'react';
import styled from 'styled-components';

import Preloader from '@/components/__old__/Preloader';
import HeaderMenu from '@/components/Layout/components/Header/components/HeaderMenu';
import OrderModal from '@/modals/OrderModal';

import { LayoutConfig } from './Layout.types';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

type Props = {
  children?: React.ReactNode;
  config: LayoutConfig;
};

function Layout({ children, config }: Props) {
  return (
    <Container>
      <Preloader />
      <Header {...config.header} />
      <HeaderMenu {...config.header} />
      <Main>{children}</Main>
      <Footer {...config.footer} />
      <OrderModal />
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default Layout;
